/* .face-box{
  border-radius: 50%;
} */

.face-box{
  transition: all 0.1s linear;
}

.face-box.orange{
  --b: 3px;   /* thickness of the border */
  --c: #ffa940;   /* color of the border */
  --w: 30px;  /* width of border */

  border: var(--b) solid #0000; /* space for the border */
  --_g: #0000 90deg,var(--c) 0;
  --_p: var(--w) var(--w) border-box no-repeat;
  background:
    conic-gradient(from 90deg  at top    var(--b) left  var(--b),var(--_g)) 0    0    / var(--_p),
    conic-gradient(from 180deg at top    var(--b) right var(--b),var(--_g)) 100% 0    / var(--_p),
    conic-gradient(from 0deg   at bottom var(--b) left  var(--b),var(--_g)) 0    100% / var(--_p),
    conic-gradient(from -90deg at bottom var(--b) right var(--b),var(--_g)) 100% 100% / var(--_p);
}

.face-box.green{
  --b: 3px;   /* thickness of the border */
  --c: #95de64;   /* color of the border */
  --w: 30px;  /* width of border */

  border: var(--b) solid #0000; /* space for the border */
  --_g: #0000 90deg,var(--c) 0;
  --_p: var(--w) var(--w) border-box no-repeat;
  background:
    conic-gradient(from 90deg  at top    var(--b) left  var(--b),var(--_g)) 0    0    / var(--_p),
    conic-gradient(from 180deg at top    var(--b) right var(--b),var(--_g)) 100% 0    / var(--_p),
    conic-gradient(from 0deg   at bottom var(--b) left  var(--b),var(--_g)) 0    100% / var(--_p),
    conic-gradient(from -90deg at bottom var(--b) right var(--b),var(--_g)) 100% 100% / var(--_p);
}

.scan-success-modal.ant-modal .ant-modal-content{
  background: transparent;
  box-shadow: none;
}

.scan-success-modal.ant-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body
.ant-modal-confirm-content{
  /* border-radius: 8px; */
  width: 100%;
}


/* version 2 */
.cam-canvas{
  position : absolute;
  margin-right : auto;
  margin-left : auto;
  left : 0;
  right : 0;
  text-align : center;
  /* width : 480px;
  height : 640px; */
  transform: scaleX(-1); /* rotate */
  z-index: 100;
}

.mask-canvas.load{
  background:rgba(0, 0, 0, 0.5);
  z-index: 102;
}

.cam-canvas.cam-loading{
  background: rgba(255, 255, 255, 0.5);
}

.cam-canvas.cam-activate{
  cursor: pointer;
  background: rgba(255, 255, 255, 0.5);
}

.cam-canvas.cam-payment-type,
.cam-canvas.cam-barcode-scanner{
  /* background: #fff; */
  opacity: 1;
}

.cam-canvas.cam-barcode-scanner{
  z-index: 1 !important;
}

.cam-canvas.cam-barcode-scanner.active{
  z-index: 101 !important;
}

.cam-canvas.cam-activate.hide,
.cam-canvas.cam-loading.hide,
.cam-canvas.cam-payment-type.hide{
  background: rgba(255, 255, 255, 0.5);
  display: none;
}

.cam-canvas.cam-barcode-scanner.hide{
  opacity: 0;
}

.modal-close-icon.hide{
  opacity: 0;
}

.date-detail.cam-canvas.z1001{
  z-index: 1001;
}

.status-notification-collapse .ant-collapse-item .ant-collapse-header{
  padding: 0;
}