.entrances-card{
    box-shadow: none !important;
}

.entrances-card .right-arrow{
    color: #8c8c8c;
    /* transform: translateX(-100%); */
    transition: all 0.5s;
}

.entrances-card:hover .right-arrow{
    color: #000;
    transform: translateX(30%);
}