.scanner video{
    transform: scaleX(-1);
    width: inherit;
    height: inherit;
}

.activity-card.selected{
    border : 1px solid var(--main-color);
    background: var(--secondary-button-color)
}

.scan-alert{
    opacity: 1;
}

.scan-alert.hide{
    opacity: 0;
}